html,body{
  height: 100vh;
}
body{
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-overflow-scrolling: touch;
  flex-direction: column;
  min-height: 100vh;
  overflow-x: hidden;
}

.bg-gray{
background-color: #D0CCCD;
}
