// 廣告輪播
.ad-swiper{
  .swiper-slide{
    position: relative;
    background: #000;
    img{
      @include transition(.5s);
      object-fit:cover;
      height: 480px;
      @include media-breakpoint-down(md) {
        min-height: calc(100vh - 60px);
      }

    }
  }
  .swiper-pagination-bullet {
    width: 15px;
    height: 15px;
  }
}
// 法律知識庫
.legal-knowledge-base{
  background-color: transparent;
  .card-title{
    color: $dark;
    height: 50px;
  }
  &:hover{
    .card-img{
        img{
          @include transform(scale(1.2));
        }
    }
    .card-title{
      color: $primary;
    }
  }
  @include media-breakpoint-down(md) {
    .card-title{
      height: 40px;
    }
  }
}
// 律師成功案例
.lawyer-success-storles{
  &-img{
    object-fit:cover;
    width: 100%;
    width: 150px;
    height: 150px;
    border-radius: 50%;
    @include transition(.5s);
    @include media-breakpoint-down(md) {
      width: 100px;
      height: 100px;
    }
  }
  &-title{
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 80px;
    color: $dark;
    line-height: 40px;
    @include transition(.5s);
    &::after{
      content:"...";
      display: block;
      position: absolute;
      bottom: 0;
      right: 0;
      width: 20px;
      height: 40px;
      padding-left: 6px;
      background-color: #fff;
      @include media-breakpoint-down(md) {
        width: 24px;
      }
    }
    @include media-breakpoint-down(md) {
      font-weight: normal !important;
      font-size: 16px;
      &::after{
        right: 0;
        width: 38px;
      }
    }
  }
  &:hover{
    .lawyer-success-storles-img{
      box-shadow: 0 0 20px rgba(0,0,0,.2);
    }
    .lawyer-success-storles-title{
      color: $primary;
    }
  }
}
// 律師服務
.lawyer-service{
  background-color: transparent;
  .card-title{
    color: $dark;
    height: 25px;
    text-align: center;
  }
  &:hover{
    .card-img{
        img{
          @include transform(scale(1.2));
        }
    }
    .card-title{
      color: $primary;
    }
  }
  @include media-breakpoint-down(md) {
    .card-title{
      height: 20px;
    }
  }
}
// 看新聞學法律
.see-journalism-law{
  @include transition(.5s);
  .card-title{
    height:100px;
    width: 100%;
    line-height: 30px;
  }
  .card-img{
    img{
      height: 180px;
    }
  }
  &:hover{
    box-shadow: 0 0 20px rgba(0,0,0,.2);
  }
}
// 我們的團隊
.index-link{
  display: block;
  position: relative;
  overflow: hidden;
  .index-link-title{
    position: absolute;
    left: 10%;
    top: 10%;
    color: $white;
    text-shadow: 0 0 3px rgba(0,0,0,.8);
    z-index: 5;
  }
  img{
    object-fit:cover;
    @include transition(.5s);
  }
  &:hover{
    img{
      @include transform(scale(1.2));
    }
  }
}
