// 個顏色陰影
@mixin box-shadow-variant($parent, $color) {
  #{$parent} {
    box-shadow:0 .5rem 1rem rgba($color, .5);
  }
}
@each $color, $value in $theme-colors {
  @include box-shadow-variant(".shadow-#{$color}", $value);
}


@mixin multiple-box-shadows {
box-shadow: $shadow-size-front rgba($black, $shadow-opacity-front),
            $shadow-size-mid rgba($black, $shadow-opacity-mid),
            $shadow-size-back rgba($black, $shadow-opacity-back);
}
