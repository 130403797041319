
/* NAVIGATION BUTTON TOGGLER
================================*/
.navigation-button-toggler{
	@include flex();
	@include flexAlignCenterVertically();
	@include flexAlignCenter();
	width: $button-toggler-width;
	height: $button-toggler-height;
	cursor: pointer;
	color: $button-toggler-icon-color;
	*{
		text-decoration:none;
		color: $button-toggler-icon-color;
	}
	.hamburger-icon{
		width: 20px;
		height: 2px;
		display: inline-block;
		font-style: normal;
		// color: $button-toggler-icon-color;
		box-shadow: inset 0 0 0 32px, 0 -6px, 0 6px;
	}
}
