@mixin button-hover($parent, $bg-color: color-yiq($color)){
  #{$parent} {
    @include transition(all .2s ease-in);
    @include hover {
      color: color-yiq($bg-color);
      background-color: $bg-color;
      border-color: darken($bg-color, 10%);
    }
  }
}

@each $color, $value in $theme-colors {
  @include button-hover(".btn-hover-#{$color}", $value);
}


.btn-ig {
  color: #fff;
  background-image: linear-gradient(224deg, #7434A8 0%, #E51720 54%, #F7D108 100%) !important;
  border-color: #ccc;
  &:hover{
    color: #fff;
  }
}
