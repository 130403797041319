.card-page{
  .card-title{
    line-height: 30px;
    height: 60px;
    display: block;
  }
}



.detail-nav-title{
  position: relative;
  border-left: 6px solid $primary;
  padding-left: 10px;
  color:$primary;
  &::after{
      content:"";
      position: absolute;
      display: block;
      width: 6px;
      left: -6px;
      top: 0;
      background-color:$black;
      height: 45%;
      z-index: 1;
  }
}
.detail-img{
  overflow: hidden;
  display: block;
  img{
    @include transition(.5s);
    object-fit:cover;
    width: 100%;
  }
}
.detail-list{
  .detail-item{
    border-bottom:solid 1px $gray-400;
    padding-bottom: 10px;
    padding-top: 10px;
    .detail-title{
      display: block;
      font-weight: bold;
      overflow: hidden;
    }

  }
  &.o1{
    .detail-item{
      .detail-title{
        line-height: 24px;
        height: 24px;
      }
      &:last-child{
        border-bottom:0;
        padding-bottom: 0;
      }
    }
  }
  &.o2{
    .detail-item{
      .detail-title{
        line-height: 24px;
        height: 48px;
      }
    }
  }
}

