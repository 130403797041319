// 主題外圍
.secBx{
    padding: 40px 0;
    @include media-breakpoint-down(md) {
        padding: 20px 0;
    }
}
// 首頁標題
.sec-inx-title{
    text-align: center;
    // 主標題
    h2{
        font-size: 30px;
        line-height: 30px;
        @extend %d-inline-block;
        letter-spacing: .2em;
        text-transform: capitalize;
        @extend %pos-r;
        font-weight: bold;
    }
    // 副標題
    .subtitle{
        color:$primary;
        font-size: 14px;
    }
}
// 標題
.sec-title {
    padding: 4px 0 4px 10px;
    border-left: 6px solid $primary;
    position: relative;
    &::after{
        content:"";
        position: absolute;
        display: block;
        width: 6px;
        left: -6px;
        top: 0;
        background-color:$black;
        height: 45%;
        z-index: 1;
    }
    @include media-breakpoint-down(md) {
        margin: 20px 0;
    }
    // 主標題
    h2{
        font-size: 30px;
        line-height: 30px;
        @extend %d-inline-block;
        @extend %pos-r;
        font-weight: bold;
        margin-bottom: 0;
    }
    // 副標題
    .subtitle{
        color:$primary;
        font-size: 14px;
    }

}
