
/* MEGAMENU
================================*/
.navigation-megamenu{
	width: 100%;
	max-height: 0;
	overflow: hidden;
	display: block;
	position: static;
	font-size: $megamenu-font-size;
	color: $megamenu-font-color;
	z-index: 20001;
	background-color: $megamenu-background-color;
	@include transition(max-height 2s cubic-bezier(0, 1, 0, 1));
	
	&.is-visible{
		max-height: $megamenu-max-height;
		border-bottom: solid 1px $megamenu-border-color;
		@include transition(max-height 5s ease-out);
	}
	
	@media (min-width: $landscape-width){
		margin-top: 40px;
		position: absolute;
		opacity: 0;
		border-top: solid 0px transparent;
		@include transition(opacity .3s linear, max-height 0s linear .6s, margin .3s);
		
		&.is-visible{
			margin-top: 0;
			opacity: 1;
			border-top: solid 1px $megamenu-border-color;
			border-bottom: none;
			box-shadow: $megamenu-box-shadow-landscape;
			@include transition(opacity .3s linear, max-height 0s linear, margin .3s);
		}
		
		// Widths
		&.navigation-megamenu-half{
			width: 50%;
		}
		&.navigation-megamenu-quarter{
			width: 25%;
		}
	}
	
	.scroll-momentum &{
		@include transform(translateZ(0));
		
		@media (min-width: $landscape-width){
			@include transform(none);
		}
	}
	
	// Fix for bootstrap grid
	[class*="container"]{
		width: 100%;
	}
	[class*="container"] [class*="col-"]{
		padding: 0;
	}
}

// Megamenu container
.navigation-megamenu-container{
	padding: $megamenu-container-padding;
	display: table;
}
