// 文字與行高
@mixin fz-size-link($font-size,$font-weight,$letter-spacing) {
  font-size: $font-size;
  font-weight: $font-weight;
  // line-height: $line-height;
  letter-spacing:$letter-spacing;
}

// 副標題
.subtitle-1{
  @include fz-size-link(1rem,400,0.009375rem);
}
.subtitle-2{
  @include fz-size-link(0.875rem,500,0.00625rem);
}
// 基本
.body-1{
  @include fz-size-link(1rem,500,0.03125rem);
}
.body-2{
  @include fz-size-link(0.875rem,400,0.015625rem);
}
// 標題
.caption{
  @include fz-size-link(0.875rem,500,0.015625rem);
}
// 上線
.overline{
  @include fz-size-link(0.75rem,500,0.1666666667em);
}


// font icon 尺寸
.fa-2x{
  font-size: 2em !important;
}
.fa-3x {
  font-size: 3em !important;
}
.fa-5x {
  font-size: 5em !important;
}
.fa-7x {
  font-size: 7em !important;
}
