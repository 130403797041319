// 整個滾動條.
::-webkit-scrollbar{
  width: 7px;
  background-color: $primary-dark;
}
// 滾動條軌道
::-webkit-scrollbar-track {
  background-color: darken($body-bg, 20%);
}
// 滾動條上的滾動滑塊
::-webkit-scrollbar-thumb {
  background: $primary-dark;
  // 滑過去
  &:hover {
    box-shadow: inset 0 0 100px 20px darken($primary-dark, 1%);
  }
}
