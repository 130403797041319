// 選取顏色
//舊版Firefox語法（61及以下版本）
//:: selection CSS偽元素選擇器是CSS第3級選擇器的草案，但是在被推薦使用前就被廢棄了。它現在在第4級偽元素選擇器擴展中。
::selection {
  color: $white;
  background-color:$primary;
}
::-moz-selection {
  color: $white;
  background-color:$primary;
}
