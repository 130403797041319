.card-img{
  overflow: hidden;
  img{
    @include transition(.5s);
    object-fit:cover;
    width: 100%;
  }
}
.card-title{
  overflow: hidden;
  font-weight: bold;
  @include transition(.5s);
  font-weight: bold;
  @include media-breakpoint-down(md) {
      font-weight: normal;
      font-size: 16px;
  }
}
