.page-header{
  background-color:$light;
  position: relative;
  img{
    @include transition(.5s);
    object-fit:cover;
    height: 300px;
    @include media-breakpoint-down(md) {
      height: 200px;
    }
  }
}
