// ============================
//       Position 位置
// ============================
%pos-a{position: absolute;}
%pos-r{position: relative;}
%pos-f{position: fixed;}
%pos-sta{position: static;}
%pos-sti{position: sticky;}

%t0{top: 0;}
%l0{left: 0;}
%r0{right: 0;}
%b0{bottom: 0;}
%py-0{top: 0;bottom: 0;}
%px-0{left: 0;right: 0;}
%p-all{
    @extend %py-0;
    @extend %px-0;
}

// 居中
%p-center{
    @extend %pos-a;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
}
%p-flex{
    display: flex;
    justify-content: center;
    align-items: center;
}

// ============================
//          overflow
// ============================
%ovh{overflow: hidden;}
%ovxh{overflow-x: hidden;}
%ovyh{overflow-y: hidden;}

%ovh-h6{word-break: normal;height: 20px;overflow: hidden;}
%ovh-h5{word-break: normal;height: 25px;overflow: hidden;}
%ovh-14{word-break: normal;height: 20px;overflow: hidden;}

// ============================
//          偽元素
// ============================
%bfaf{content: "";}

// ============================
//         display
// ============================
%d-block{display: block;}
%d-none{display: none;}
%d-inline-block{display: inline-block;}

// ============================
//         手指 (cursor)
// ============================
%c-pointer{cursor: pointer;}

// ============================
//         透明度
// ============================
%opacity0{
    opacity:0;
    filter:alpha(opacity=0);
}
%opacity1{
    opacity:1;
    filter:alpha(opacity=1);
}

