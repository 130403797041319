.gototop{
  display: none;
  position: fixed;
  bottom: 25px;
  right: 1%;
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  cursor: pointer;
  font-weight: 700;
  text-transform: uppercase;
  z-index: $zindex-dropdown;
  background-color: $primary-dark;
  @include border-radius(50%);
  color: $white;
  a{
    color: $white;
  }
  &:hover{
   background:$black;
  }
}
